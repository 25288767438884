class mainMenu {
    _selector = {
        mainMenu: '.js-main-menu-overlay',
        menu: '.js-menu',
        retour: '.js-retour',
    }
    _elements = {
        mainMenu: null,
        menu: null,
        retour: null,
    }

    constructor(){
        this.initElements();
        this.listen();
    }

    initElements(){
        this._elements.mainMenu = document.querySelectorAll(this._selector.mainMenu);
        if(this._elements.mainMenu.length > 0){
            this._elements.mainMenu = this._elements.mainMenu[0]; // on récupère l'élément "main-menu" qui sera de toute manière unique
        }
        this._elements.menu = this._elements.mainMenu.querySelectorAll(this._selector.menu);
        this._elements.retour = this._elements.mainMenu.querySelectorAll(this._selector.retour);
    }

    listen(){
        //Click on a level 1 element
        this._elements.menu.forEach((elm) => {
            elm.addEventListener('click', (e) => {
                if (elm.classList.contains('has-child')) {
                    e.preventDefault();
                    this.toggleItem(elm);

                }
            });
        });

        //Click on the "retour" button
        this._elements.retour.forEach((elm) => {
            elm.addEventListener('click', (e) => {
                // e.preventDefault();
                // const parent = elm.closest('.sub-menu').previousElementSibling;
                // if(parent.classList.contains('active') ){
                //     this.toggleItem(parent);
                // }
                this.resetAll();
            });
        });
    }

    toggleItem(elm){
        this.resetAll();
        elm.classList.add('active');
        this._elements.mainMenu.classList.add('sub-menu-active');
    }

    resetAll(){
        this._elements.menu.forEach((elm) => {
            elm.classList.remove('active');
            this._elements.mainMenu.classList.remove('sub-menu-active');
        });
    }
}

const theMainMenuOverlay = new mainMenu();