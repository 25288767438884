(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-cover-home');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            pagination: true,
            autoHeight: true
        }).on( 'pagination:mounted', function ( data ) {
            const splider = data.items[0].button.closest(".splide");

            let i=1;
            data.items.forEach( function ( item ) {
                let slide = null;
                if (i < 10){
                    slide = splider.querySelector('[id*="slide0'+i+'"]');
                }else{
                    slide = splider.querySelector('[id*="slide'+i+'"]');
                }

                let title = null
                const attr = slide.querySelector('.title').getAttribute('data-slide');
                console.log(attr);
                if(attr){
                    title = attr;
                }else{
                    title = slide.querySelector('.title').textContent;
                }

                item.button.textContent = String( title );
                i++;
            } );
        } ).mount();
    });

})();