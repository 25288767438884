(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-timeline');
    sliders.forEach(slider => {

        const splide = new Splide(slider, {
            gap: 0,
            trimSpace: false
        }).mount();
    });

})();