(function () {
    // clique sur le toogle du switch
    const strateSwitch = document.querySelector('.js-strate-switch');

    if (strateSwitch) {
        const input =  strateSwitch.querySelector('input');
        const linkExo = strateSwitch.querySelector('.link-exo');
        const linkEndo = strateSwitch.querySelector('.link-endo');

        // si la checkbox passe à checked, on va sur la page endo ('je suis sur place'), sinon sur la page exo ('je prépare')
        input.addEventListener('change', function(){
            document.location.href = (this.checked) ? linkEndo.href : linkExo.href;
        });
    }
})();